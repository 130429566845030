













import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component({
  components: {
  },
})
export default class CommentBlock extends Vue {
  @Prop(String)
  name: string

  @Prop(String)
  comment: string

  @Prop(String)
  job: string

  get classes() {
    return [
      'comment-block',
    ]
  }

  get isH5() {
    return AppModule.isH5
  }
}
