























































































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import InfoBlock from './components/InfoBlock.vue'
import CommentBlock from './components/CommentBlock.vue'

@Component({
  components: {
    InfoBlock,
    CommentBlock
  },
})
export default class Member extends Vue {
  get isH5() {
    return AppModule.isH5
  }

  get images() {
    return [
      'https://spics.inctrip.com/b261c7fc4681ec1bd3bdb08119393f79.png',
      'https://spics.inctrip.com/087e91c42a6b39b69f6e7b24c1b6887c.png',
      'https://spics.inctrip.com/dcfef7d129d4b3731f276201fc0eb1ba.png',
      'https://spics.inctrip.com/87ff24ca7dfa8fd3a808774c7204881c.png',
      'https://spics.inctrip.com/d8e2ef6675c060a661e0ed2427b40ae7.png',
      'https://spics.inctrip.com/69183368e9765f01938a8ebb5983ad08.png'
    ]
  }
}
